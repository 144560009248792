// import select2 from 'select2';


(($, select2) => {
    // select2();

    $('#request_type').on('select2:select', function (e) {
        let data = e.params.data;
        console.log(data.element);
        if(data.element.value === 'vacations') {
            document.querySelector('.wrap-vacations').classList.remove('d-none');
        }else {
            document.querySelector('.wrap-vacations').classList.add('d-none');
        }
    });



})(window.$);
