(function($){
    //https://api.hacienda.go.cr/fe/ae?identificacion=numberID
    const numberID = document.querySelector('#number_id');

    if(numberID !== null) {
        const type_id = document.querySelector('#type_id');

        numberID.addEventListener('keyup', (ev) => {
            if(type_id.value === 'id_physical') {
                let target = ev.target,
                    value = target.value.replace(/[-_]/g, '');

                if(value.length === 9) {
                    axios
                        .get(`/dashboard/employee/person/${value}`)
                        .then(response => {
                            if(response.status === 200) {
                                if(response.data?.results[0]) {
                                    let person = response.data?.results[0];
                                    document.querySelector('#first_name').value = person.firstname;
                                    document.querySelector('#last_name').value = person.lastname1;
                                    document.querySelector('#second_last_name').value = person.lastname2;
                                }
                            }
                        })
                        .catch(err => {
                            console.log('err getting id person', err)
                        });
                }
            }
        });
    }

    $('.custom-file input').on('change', event => {
        if(event.target.files.length > 0) {
            let myFile = event.target.files[0];
            let reader = new FileReader();
                console.log(myFile)
            reader.addEventListener('load', function (e) {
                if(e.target.error === null) {
                    $('label[for="' + event.target.id + '"]').html(myFile.name);
                    $('.' + event.target.id).removeClass('d-none');
                    $(event.target).addClass('form-control').addClass('is-valid')
                }
            });
            reader.readAsBinaryString(myFile);
        }
    });

    const emailRandom = $('#email_random');

    if(emailRandom.length) {
        emailRandom.on('change', event => {
            let target = event.target;
            if(target.checked) {
                let email = Math.round(Math.random()*100000)+"@sys-human.com";
                $('#email').val(email);
            } else {
                $('#email').val('');
            }
        })
    }

    const is_manager = document.querySelector('#is_manager');
    if(is_manager) {
        is_manager.addEventListener('change', (evt) => {
            evt.preventDefault();

            const jobInput = document.querySelector('#job_id');
            if ((jobInput.value.trim() === "" || jobInput.value.trim().length <= 0) && evt.target.checked) {
                alert("Por favor seleccionar el puesto");
                evt.target.checked = false;
                return false;
            } else {
                showDepartment(jobInput.value);
                document.querySelector('#department_to_manage').classList.toggle('d-none');
            }
        });
    }

    const jobInput = $('#job_id');
    jobInput.on('select2:select', function (ev) {
        showDepartment(ev.params.data.id);
    })

    const showDepartment = (job_id) => {
        axios
            .get(`/dashboard/job/${job_id}`)
            .then(response => {
                if(response.status === 200) {
                    document.querySelector('#department_to_manage span').textContent = response.data;
                }
            })
            .catch(err => {
                console.log('err', err)
            });
    }

})(window.$);
