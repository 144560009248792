import {forEach} from "lodash";

// https://electrictoolbox.com/javascript-add-options-html-select/
(($) => {
    if(document.querySelector('.select-company') && document.querySelector('#department_id')) {
        const department = document.querySelector('#department_id');

        $('.select-company').on('change', function(evt) {
            let companyId = evt.target.value;

            if(companyId > 0) {
                department.innerHTML = '';
                axios
                    .get(`/api/v1/company/${companyId}/departments`)
                    .then(response => {
                        if(response.status === 200) {
                            let option = document.createElement("option");
                            option.text = "Seleccionar departamento";
                            department.add(option);

                            forEach(response.data, (value) => {
                                option = document.createElement("option");
                                option.value = value.id;
                                option.text = value.name;
                                department.add(option);
                            });
                        }
                    })
                    .catch(err => console.log('err', err));
            }else {
                department.innerHTML = '';
            }
            evt.preventDefault();
        });
    }
})($);
