(function($){

    $('.datepicker-payments')
        .datepicker({
            format: 'yyyy-mm-dd'
        }).on('changeDate', function(ev) {

            console.log(ev.target.id, ev.viewMode);
            if((ev.target.id === "date_in" || ev.target.id === "date_out") && ev.viewMode === "days") {
                $(ev.target).datepicker('hide');
            }

        });

    if(document.querySelector('#advance-notice') !== null) {
        const selectAdvice = document.querySelector('#advance-notice');

        selectAdvice.addEventListener('change', ev => {
            if (ev.target.value === 'notice-pending') {
                document.querySelector('.wp-pending-days').classList.remove('d-none');
            } else {
                document.querySelector('.wp-pending-days').classList.add('d-none');
            }
        });
    }
})(window.$);
