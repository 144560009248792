import currency from 'currency.js';

(function($){
    setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
    }, 2000);

    const CRC = value => currency(value, { symbol: '₡' });

    const datepicker = $('.datepicker')
            .datepicker({
                format: 'yyyy-mm-dd'
            })
            .on('changeDate', function(ev){
                if(ev.viewMode === 'days') {
                    $(this).datepicker('hide');
                }
            });
    let calendarDays = 30;

    /* define the employee object to use it globally */
    window.employee = {};

    // this is for the payroll module /dashboard/payroll/create when user change between employees
    window.getEmployeeInfo = function (id) {
        return axios
            .get(`/dashboard/employee/${id}`)
            .then(response => {
                if (response.status === 200) {
                    let employee = response.data.data;
                    window.employee = employee;
                    calendarDays = employee.type_payment_raw === 'weekly' ? 26 : calendarDays;
                    let daily_salary = ( employee.current_salary / calendarDays ).toFixed(2);

                    if($('#department').length)
                        $('#department').val(employee.department.name);

                    if($('#job').length)
                        $('#job').val(employee.job.name);

                    if($('#type_payment').length)
                        $('#type_payment').val(employee.type_payment);

                    if($('#current_salary').length)
                        $('#current_salary').val(CRC(employee.current_salary ?? employee.salary).format());

                    if($('#salary').length)
                        $('#salary').val(CRC(employee.salary).format());

                    if($('#daily_salary').length)
                        $('#daily_salary').val(CRC(daily_salary).format());

                    if($('#salary_hour').length)
                        $('#salary_hour').val(CRC(daily_salary/8).format());

                    if($('#average_salary').length)
                        $('#average_salary').val(CRC(daily_salary*calendarDays).format());

                    $('.wrap-detail input:not(#social_guarantees)').removeAttr('readonly');

                    /* Validate if this is input exist, it is from payroll.js */
                    if($('#payment_date').length) {
                        $('#payment_date').removeAttr('disabled');
                    }
                }
            })
            .catch(err => {
                console.log('err', err);
            });
    }

    window.employeeSelect = function() {
        if($('.employee-select').length) {
            let employee_id = $('.employee-select').val();

            if(employee_id !== "") {
                getEmployeeInfo(employee_id);
            }

            $('.employee-select').on('select2:select', function (e) {
                let data = e.params.data,
                    inputId = data.element.parentElement.id;
                if (data.id !== "" && data.id !== undefined) {
                    getEmployeeInfo(data.id);
                }
            });
        }
    }

    employeeSelect();

    // window.getNumberFormat = value => new Intl.NumberFormat('en-US', options).format(value);

    window.percentage = (num, per) => {
        return (num/100)*per;
    }

    window.replaceRegEx = (value, regex, replace) => {
        return value.replace(regex, replace);
    }
})(window.$);
