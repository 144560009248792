import select2 from 'select2';
import currency from 'currency.js';

(($, select2) => {
    select2();
    const CRC = value => currency(value, { symbol: '₡' });

    if(document.querySelector('.page-bonus')) {
        let payrollSelect = $('#employee_id');

        if(payrollSelect.length) {
            payrollSelect.on('select2:select', function (e) {
                let data = e.params.data;

                if (data.id !== "" && data.id !== undefined) {
                    document.querySelector('.form-bonus').reset();

                    return axios
                        .get(`/dashboard/bonus/calculate/${data.id}`)
                        .then(response => {
                            if (response.status === 200) {
                                const payments = response.data.data;
                                let total = 0, input = null;
                                payments.map(bonus => {
                                    total += bonus.total_amount;
                                    input = document.querySelector(`#${bonus.month}`)
                                    input.classList.remove('salary-month');
                                    input.value = `${bonus.total_amount}`;
                                });
                                document.querySelector(`#total_amount`).innerHTML = CRC(`${total/12}`).format();
                                document.getElementById('btn-calc').classList.remove('d-none');
                                document.getElementById('btn-save').classList.remove('d-none');
                                document.getElementById('employee_bk').value = data.id;
                            }
                        });
                }
            });

            if($('#employee_bk').length){
                payrollSelect.val($('#employee_bk').val());
                payrollSelect.trigger('change');
                document.getElementById('btn-calc').classList.remove('d-none');
                document.getElementById('btn-save').classList.remove('d-none');
            }
        }

        const formBonus = document.querySelector('.form-bonus');

    if(formBonus)
    {
        formBonus.addEventListener('submit', evt => {
            if(document.querySelector('[name="_method"]').value !== 'PUT') {
                evt.preventDefault();
                const inputsSalary = formBonus.querySelectorAll('.salary'),
                    btnSubmitter = evt.submitter;
                let total = 0;

                if(btnSubmitter.id === 'btn-calc')
                {
                    Array.from(inputsSalary).map(input => {
                        console.log(input.value);
                        if(parseFloat(input.value)) {
                            total += parseFloat(input.value);
                        }
                    });

                    document.querySelector(`#total_amount`).innerHTML = CRC(`${total/12}`).format();
                } else {
                    payrollSelect.val($('#employee_bk').val());
                    payrollSelect.trigger('change');
                    formBonus.submit();
                }
            }
        });
    }
    }

})(window.$, select2);
