import './bootstrap';
import 'laravel-datatables-vite';
import './libs/index';
import './general';

import.meta.glob([
    '../images/**/*',
    '../fonts/**/*',
]);

import Alpine from 'alpinejs';
import Inputmask from "inputmask";

window.Alpine = Alpine;

Alpine.start();

/**
 * Modules
 */
import './modules';
