import select2 from 'select2';

((select2) => {
    select2();

    const provinces = document.querySelector('#province_id'),
        cantons = document.querySelector('#canton_id'),
        districts = document.querySelector('#district_id');

    const getData = async (method, id, ev = null) => {
        await axios
            .get(`/api/v1/${method}/${id}`)
            .then(response => {
                const data = response.data.data;
                // console.log(data)

                data.map(option => {
                    let opt = document.createElement("option");
                    opt.value = option.id;
                    opt.text = option.name;

                    if(method === 'cantons') {

                        if(option.id === parseInt(cantons.dataset.canton)) {
                            opt.selected = true;
                        }

                        cantons.add(opt);
                    } else if(method === 'districts') {
                        if(option.id === parseInt(districts.dataset.district)) {
                            opt.selected = true;
                        }

                        districts.add(opt);
                    }

                })
            })
            .catch(err => {});

        if(ev !== null) {
            if(method === 'cantons'){
                districts.innerHTML = '';
                getData('districts', $('#canton_id').val());
            }
        }
    }

    if(provinces !== null) {
        provinces.addEventListener('change', (ev) => {
            console.log(ev.target)
            if(ev.target.value > 0) {
                cantons.innerHTML = '';
                districts.innerHTML = '';
                const e = new Event("change");
                getData('cantons', ev.target.value, e);
            }
        });

        if(provinces.value.length > 0) {
            getData('cantons', provinces.value, new Event("change"));
        }
    }

    if(cantons !== null) {
        $('#canton_id')
            .select2({
                theme: 'bootstrap',
                width: 'resolve'
            })
            .on("select2:select", function (ev) {
                districts.innerHTML = '';
                getData('districts', $(this).val());
            });
    }
})(select2);
