import select2 from 'select2';
import currency from 'currency.js';
import moment from 'moment';

(($, select2) => {
    select2();

    if(document.querySelector('.page-bonus')) {
        return;
    }

    if(document.querySelector('.page-payroll')) {
        const CRC = value => currency(value, { symbol: '₡' });

        //looking for the employe by ID or name
        let payrollSelect = $('#employee_id'),
            wrapLatestPayment = document.querySelector('.wrap-latest-payment');

        if(payrollSelect.length) {
            payrollSelect.on('select2:select', function (e) {
                let data = e.params.data,
                    inputId = data.element.parentElement.id,
                    inputToTrigger = $(`#${inputId}`);

                if (data.id !== "" && data.id !== undefined) {
                    payrollSelect.val(data.id);
                    payrollSelect.trigger('change');

                    getEmployeeInfo(data.id);
                    wrapLatestPayment.classList.remove('d-none');

                    $(`.${inputId}.invalid-feedback`).addClass('d-none').removeClass('d-block');
                } else {
                    inputToTrigger.next("span.select2").addClass('is-invalid')
                    $(`.${inputId}.invalid-feedback`).removeClass('d-none').addClass('d-block');

                    return false;
                }
            });

            if($('input[name="_method"]').length) {
                getEmployeeInfo($('#number_id').val());
            }
        }

        function calcTypeTax(total_salary, taxes) {
            for (const key in taxes) {
                const tax = taxes[key];
                if (total_salary >= tax.start && (total_salary < tax.end || tax.end === null)) {
                    return key;
                }
            }
            return null;
        }

        function calcTaxes(total_salary) {
            const taxes = {
                tax_10: {
                    start: 929001,
                    end: 1363001,
                    tax: 0.10,
                    total: (1363001 - 929001) * 0.10,
                },
                tax_15: {
                    start: 1363001,
                    end: 2392001,
                    tax: 0.15,
                    total: (2392001 - 1363001) * 0.15,
                },
                tax_20: {
                    start: 2392001,
                    end: 4783001,
                    tax: 0.20,
                    total: (4783001 - 2392001) * 0.20,
                },
                tax_25: {
                    start: 4783001,
                    end: null,
                    tax: 0.25,
                    total: 0,
                },
            };

            const type_tax = calcTypeTax(total_salary, taxes);
            if (type_tax === null) return 0;

            let total = 0;
            for (const key in taxes) {
                const tax = taxes[key];
                if (key === type_tax) {
                    total += (total_salary - tax.start) * tax.tax;
                    break;
                }
                total += tax.total;
            }

            return total;
        }

        const calcOvertime = (daily_salary, key) => {
            const overtimes = {
                'overtime' : 8,
                'worked_hours_nocturnal' : 6,
                'overtime_mix' : 7,
            };

            return currency(daily_salary).value / overtimes[key];
        }

        const salary_hour = document.querySelector('#salary_hour'),
            worked_hours = document.querySelector('#worked_hours'),
            worked_hours_nocturnal = document.querySelector('#worked_hours_nocturnal'),
            holiday = document.querySelector('#holiday'),
            overtime = document.querySelector('#overtime'),
            overtime_mix = document.querySelector('#overtime_mix'),
            overtime_mix_holiday = document.querySelector('#overtime_mix_holiday'),
            overtime_nocturnal = document.querySelector('#overtime_nocturnal'),
            overtime_nocturnal_holiday = document.querySelector('#overtime_nocturnal_holiday'),
            total_salary = document.querySelector('#total_salary'),
            social_guarantees = document.querySelector('#social_guarantees'),
            alimentary_pension = document.querySelector('#alimentary_pension'),
            salary_advance = document.querySelector('#salary_advance'),
            garnishments = document.querySelector('#garnishments'),
            another_deductions = document.querySelector('#another_deductions'),
            total_deductions = document.querySelector('#total_deductions'),
            bonus = document.querySelector('#bonus'),
            net_salary = document.querySelector('#net_salary'),
            daily_salary = document.querySelector('#daily_salary'),
            subtotal = document.querySelector('#subtotal'),
            inputs = document.querySelectorAll('.wrap-payroll__detail .salary'),
            ccss = social_guarantees?.dataset?.ccss ?? '',
            paymentControl = document.querySelector('#payment_control') ? document.querySelector('#payment_control').value : '',
            include_ccss = document.querySelector('#include_ccss');

        let totalWorkedHours = 0,
            subtotalCalc = 0,
            taxes = 0,
            overtimeCalc = 0,
            overtimeMixCalc = 0,
            overtimeMixHolidayCalc = 0,
            overtimeNocturnalCalc = 0,
            overtimeNocturnalHolidayCalc = 0,
            totalBonus = bonus ? bonus.value : 0;

        const calcSocialGaranties = amount => currency(currency(amount).value * (ccss / 100));

        inputs.forEach(element => {
            if(element.classList.contains('total_salary')) {
                element.addEventListener('focusout', ev => {
                    totalWorkedHours = paymentControl === 'days' ? currency(worked_hours.value).value * 8 : currency(worked_hours.value).value;

                    overtimeCalc = currency( calcOvertime(daily_salary.value, 'overtime') * 1.5 * overtime.value).value;
                    overtime.closest('.input-group').querySelector('span').textContent = CRC(overtimeCalc).format();

                    overtimeMixCalc = currency( calcOvertime(daily_salary.value, 'overtime_mix') * 1.5  * overtime_mix.value).value;
                    overtime_mix.closest('.input-group').querySelector('span').textContent = CRC(overtimeMixCalc).format();

                    overtimeNocturnalCalc = currency( calcOvertime(daily_salary.value, 'worked_hours_nocturnal') * 1.5  * overtime_nocturnal.value).value;
                    overtime_nocturnal.closest('.input-group').querySelector('span').textContent = CRC(overtimeNocturnalCalc).format();

                    overtimeMixHolidayCalc = currency( calcOvertime(daily_salary.value, 'overtime') * 2  * overtime_mix_holiday.value).value;
                    overtime_mix_holiday.closest('.input-group').querySelector('span').textContent = CRC(overtimeMixHolidayCalc).format();

                    overtimeNocturnalHolidayCalc = currency( calcOvertime(daily_salary.value, 'overtime') * 2  * overtime_nocturnal_holiday.value).value;
                    overtime_nocturnal_holiday.closest('.input-group').querySelector('span').textContent = CRC(overtimeNocturnalHolidayCalc).format();

                    total_salary.value = ( currency(salary_hour.value).value * currency(totalWorkedHours).value ) + ( currency(holiday.value).value * (currency(daily_salary.value).value * 1) ) + overtimeCalc + overtimeMixCalc + overtimeNocturnalCalc;

                    // Add format
                    total_salary.value = CRC(total_salary.value).format();
                    social_guarantees.value = !include_ccss.checked
                        ? CRC(calcSocialGaranties(total_salary.value)).format()
                        : CRC(calcSocialGaranties(total_salary.value).value + calcSocialGaranties(bonus.value).value).format();

                    subtotalCalc = currency(total_salary.value).value - currency(social_guarantees.value).value;
                    subtotal.value = CRC(subtotalCalc).format();
                    taxes = calcTaxes(currency(total_salary.value).value);
                    document.querySelector('#total_taxes').innerText = '' + CRC(taxes).format();
                    document.querySelector('#taxes').value = '' + CRC(taxes).format();

                    net_salary.value = !include_ccss.checked
                        ? CRC(currency(subtotal.value).value - currency(total_deductions.value).value - currency(taxes).value + currency(totalBonus).value).format()
                        : CRC(currency(subtotal.value).value - currency(total_deductions.value).value - currency(taxes).value ).format();
                });
            }

            if(element.classList.contains('total_deductions')) {
                element.addEventListener('focusout', ev => {
                    total_deductions.value = currency(alimentary_pension.value).value + currency(salary_advance.value).value + currency(garnishments.value).value + currency(another_deductions.value).value;
                    total_deductions.value = CRC(total_deductions.value).format();

                    net_salary.value = CRC(currency(subtotal.value).value - currency(total_deductions.value).value + currency(bonus.value).value).format();
                });
            }
        });

        if(include_ccss) {
            include_ccss.addEventListener('change', (evt) => {
                evt.preventDefault();
                total_salary.value = evt.target.checked ? currency(total_salary.value).value + currency(bonus.value).value : currency(total_salary.value).value - currency(bonus.value).value;
                total_salary.value = CRC(total_salary.value).format();

                subtotalCalc = currency(total_salary.value).value - currency(social_guarantees.value).value;
                subtotal.value = CRC(subtotalCalc).format();

                social_guarantees.value = CRC(calcSocialGaranties(total_salary.value).value).format();
                net_salary.value = !evt.target.checked
                    ? CRC(currency(subtotal.value).value - currency(total_deductions.value).value - currency(taxes).value + (currency(bonus.value).value - calcSocialGaranties(bonus.value).value)).format()
                    : CRC(currency(subtotal.value).value - currency(total_deductions.value).value - currency(taxes).value ).format();
            });
        }

        /**
         * Button to load latest values
         */
        const loadLatestValues = document.querySelector('.btn-load-payment');
        if(loadLatestValues) {
            loadLatestValues.addEventListener('click', ev => {
                ev.preventDefault();
                const employeeId = $('#employee_id').select2('data'),
                    e = new Event("focusout"),
                    worked_hours = document.querySelector('#worked_hours');

                axios
                .get(`/dashboard/payroll/last-payment/${employeeId[0].id}`)
                .then(response => {
                        if(response.status === 200) {
                            let payment = response.data.data;

                            if(payment.length)
                            {
                                worked_hours.value = payment.worked_hours;
                                document.querySelector('#holiday').value = payment.holiday;
                                document.querySelector('#overtime').value = payment.overtime;
                                document.querySelector('#alimentary_pension').value = payment.alimentary_pension;
                                document.querySelector('#salary_advance').value = payment.salary_advance;
                                document.querySelector('#garnishments').value = payment.garnishments;
                                document.querySelector('#another_deductions').value = payment.another_deductions;
                                document.querySelector('#total_deductions').value = payment.total_deductions;
                                document.querySelector('#bonus').value = payment.bonus;
                                document.querySelector('#comment').value = payment.comment;

                                worked_hours.dispatchEvent(e);
                            }
                        }
                    })
                    .catch(err => {
                        console.log('err getting id person', err)
                    });
            });
        }

        /* Dispacth event focusout when edit page is load */
        if(document.querySelector('#action').value == 'Update') {
            document.querySelector('#worked_hours').dispatchEvent(new Event('focusout')); // Disparar el evento
        }

        $('#payment_date').on('changeDate', function(ev){
            let params = {
                date: moment(ev.date).format('YYYY-MM-DD'),
                employee_id: window.employee.id,
                type_payment: window.employee.type_payment_raw,
            };

            axios
                .post(`/dashboard/medical-leave-search`, params)
                .then(response => {
                    console.log(response);

                    if(response.status === 200) {
                        document.querySelector('#days_leave').value = response.data;
                    }
                })
                .catch(err => {
                    console.log('medical-leave/search', err)
                });
    });
    }

})(window.$, select2);
