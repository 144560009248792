import select2 from 'select2';

(function($, select2){
    select2();
    const licenseDriver = $('#license_driver');

    //General for all selects
    $('.cst-select2').select2({
        theme: "bootstrap",
        width: 'resolve',
        dropdownAutoWidth: true
    });

    //Form Employee resources/views/employee/form-fields.blade.php
    licenseDriver.select2({
        theme: 'classic',
        width: 'resolve'
    });
    licenseDriver.on("select2:select", function (ev) {
        let values = $(this).val(),
            clone = $('.wp-file-licence .custom-file').clone(true);
        $('.wp-all-licence').html();

        values.map(value => {
            if(values.length === 1) {
                $('.wp-file-licence').removeClass('d-none');
            }

            if(clone.find('#path_licence_file_'+value).length === 0 && values.length > 1) {
                clone.find('input').attr('id','path_licence_file_' + value);
                clone.find('label').attr('for','path_licence_file_' + value);
                clone.appendTo('.wp-all-licence');
            }
        })
    });

    licenseDriver.on("select2:unselect", function (ev) {
        if($(`#path_licence_file_${ev.params.data.id}`)) {
            $(`#path_licence_file_${ev.params.data.id}`).closest('.custom-file').remove()
        }
    });

})(window.$, select2);
