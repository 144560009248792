import select2 from 'select2';

(($, select2) => {
    select2();

    let codesJob = $('.codes-job');

    if(codesJob.length) {
        codesJob.on('select2:select', function (e) {
            let data = e.params.data,
                inputId = data.element.parentElement.id,
                inputToTrigger = inputId === 'code_id' ? $('#code_ccss') : $('#code_id');

            if (data.id !== "" && data.id !== undefined) {
                inputToTrigger.val(data.id)
                inputToTrigger.trigger('change');
            }
        });
    }

})(window.$, select2);
