import select2 from 'select2';

(($, select2) => {
    select2();

    /**************
    *      Mask for type if IDs
    * **************/

    //https://github.com/RobinHerbots/Inputmask
    const type_id = document.querySelector('#type_id'),
    number_id_input = document.querySelector('#number_id');

    //fisica X-XXXX-XXXX
    //Dimex XXX XXX XXX XXX
    if(type_id) {
        $('#type_id')
        .select2({
            theme: 'bootstrap',
            width: 'resolve'
        })
        .on("select2:select", function (ev) {
            let data = ev.params.data,
            target = data.element;
            number_id_input.value = '';

            switch (target.value) {
                case 'id_physical':
                Inputmask({"mask": "9-9999-9999", jitMasking : true, placeholder: "X-XXXX-XXXX"}).mask(number_id_input);
                number_id_input.setAttribute('maxlength', "11");
                break;
                case 'id_dimex':
                Inputmask({"mask": "999999999999", jitMasking : true, placeholder: "XXXXXXXXXXXX", alias: "numeric"}).mask(number_id_input);
                number_id_input.setAttribute('placeholder', "XXXXXXXXXXXX");
                number_id_input.setAttribute('maxlength', "12");
                break;
                default:
                if (number_id_input.inputmask)
                    number_id_input.inputmask.unmaskedvalue()
                number_id_input.removeAttribute("maxlength");
                break;
            }
        });
    }

    /**************
    *      Mask for salaries
    * **************/
    /**
    * Mask for bonus page => calculo aguinaldo
    */
    if(document.querySelectorAll('.salary-month').length > 0)
    {
        const bonusInputs = document.querySelectorAll('.salary-month'),
            options = {
                alias: 'numeric',
                groupSeparator: ',',
                autoGroup: true,
                digits: 2,
                digitsOptional: false,
                prefix: '₡ ',
                placeholder: '0',
                rightAlign: false,
                radixPoint: '.',
                onBeforeMask: function (value, opts) {
                    return value;
                },
                removeMaskOnSubmit: true,
            };
        Array.from(bonusInputs).map(input => Inputmask(options).mask(input))
    }

    /**************
    *      Mask for datepicker inputs
    * **************/
    const datepickers = document.querySelectorAll(".datepicker");

    if(datepickers.length) {
        datepickers.forEach(input => {
            input.placeholder = "YYYY-mm-dd";
        });
    }

    if($("input[type=email]").length){
        const inputEmails = document.querySelectorAll('input[type=email]');
        Array.from(inputEmails).map(email => Inputmask({ alias: "email" }).mask(email))
    }

})(window.$, select2);
